// Config values
$platform-name: "bipolaruk-refresh";
$assets-path: "../assets/";

// Colour
// $_blue: #005481;
$_blue: #0690E6;
$_red: #F11D1C;

// $_gradient-red-dark-blue: linear-gradient(90deg, rgba(241,29,28,1) 0%, rgba(0,84,129,1) 100%);
$_gradient-red-dark-blue: linear-gradient(90deg, rgba(241,29,28,1) 0%, rgba(6,144,230,1) 100%);


$brand-primary: $_blue;
$brand-primary-text-contrast-override: null;
$brand-secondary: $_red;
$brand-secondary-text-contrast-override: null;

$donate-colour: $_red;

// Create greyscale
$black: #000;
$grey-dark: #191919;
$grey: #464646;
$grey-light: #737373;
$grey-lighter: #eceeef;
$grey-lightest: #F7F7F7;
$white: #FFF;

// Layout
$container-max-width: 1280px;
$gap-width: 40px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: true;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.3s;
$transition-curve: ease-in-out;

// Logo
$logo-width: 200px;
$logo-height: 120px;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile-width: $logo-width * 0.55;
$logo-mobile-height: $logo-height * 0.55;

// Typography
// $font-family-base: 'URW Geometric W05 Regular', sans-serif;
$font-family-base: 'URW Geometric W05 Medium', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'URW Geometric W05 Extra Bold', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: $grey-dark;
$headings-text-transform: lowercase;
$headings-letter-spacing: initial;

$script-font-family: 'Ernest and Emily Slanted W05 R';

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $white;
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: none;
$btn-padding-x: 1.25em;
$btn-padding-y: 0.625em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-large;
// $btn-text-transform: uppercase;
// $btn-text-transform: none;
$btn-text-transform: lowercase;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: $white;

// Social icon
$social-icons-background-colour: transparent;
$social-icons-colour: $grey-light;
$social-icons-hover-colour: $_blue;
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.4rem;
$social-icons-gap: 5px;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $grey-light;
$social-icons-header-hover-colour: $_blue;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_red;

// Share this page
$share-enabled: true;
$share-text-align: left;
$share-label-font-family: $headings-font-family;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: 0;
$card-border: 0;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer $spacer $spacer * 1.5 $spacer;
// $card-details-background-colour: rgba($_blue, 0.85);
$card-details-background-colour: rgba($white, 0.9);
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: text-contrast($card-details-background-colour);
$card-heading-font-size: $font-size-h2;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $white;
$card-footer-background-gradient: none;
$card-footer-colour: $body-colour;
$card-footer-padding: .5rem $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above;

// // Card text overlay
// $card-text-overlay-show-summary-on-hover: true;
// $card-text-overlay-summary-always-on: false;
// $card-text-overlay-details-max-width: 100%;
// $card-text-overlay-details-position-y: bottom; // top, center, bottom
// $card-text-overlay-details-position-x: left; // left, center, right

// // Card text over
// $card-text-over-image-vertical-text-position: center; // top, center or bottom
// $card-text-over-image-darken-image-amount: 0.5;
// $card-text-over-image-tint-colour: $black;
// $card-text-over-image-show-summary-on-hover: true;
//
// // Card side by side
// $card-side-by-side-image-position: left;
// $card-side-by-side-image-width: 50%;
// $card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
// $card-side-by-side-vertical-text-position: top; // top, center or bottom
// $card-side-by-side-breakpoint: map-get(
//   $breakpoints,
//   sm
// ); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: none;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.05;
// $card-hover-details-background-colour: rgba($_red, .85);
$card-hover-details-background-colour: rgba($white, 0.9);
$card-hover-details-background-gradient: $card-details-background-gradient; // none or CSS gradient
$card-hover-heading-colour: text-contrast($card-hover-details-background-colour);
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: $card-details-background-colour;
$event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer * 0.5;
$event-card-floating-date-margin-x: $spacer * 0.5;

// Menu admin
$menu-admin-enabled: false;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: $grey-lighter;
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: right;
$menu-admin-link-colour: $grey-lighter;
$menu-admin-absolute: true;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
// $menu-admin-to-nav-breakpoint: map-get($breakpoints, lg);
$menu-admin-to-nav-breakpoint: map-get($breakpoints, md);

// Header
$header-background-colour: $white;
$header-box-shadow: $box-shadow;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: $spacer * .5;
$header-content-margin-bottom: $spacer * .5;
$header-content-padding-top: $spacer * .5;
$header-content-padding-bottom: $spacer * .5;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: false;

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 5px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-height: 40px;
$header-search-input-margin-right: -40px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: rgba($body-colour, 0.75);
$header-search-input-border-radius: 0;
$header-search-input-border-colour: $_blue;
$header-search-input-border-width: 0px 0px 2px 0px;
$header-search-input-background-colour: $grey-lightest;
$header-search-input-colour: $body-colour;
$header-search-input-focus-background-colour: $grey-lightest;
//$header-search-input-focus-colour: transparent;

// Search - button
$header-search-button-width: 40px;
$header-search-button-height: 40px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $social-icons-header-colour;
$header-search-button-border-radius: $border-radius;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: 0.01px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0; // Should already contrast enough with the menu bg
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: $white;
$nav-search-button-icon-colour: $_blue;

// Social icons
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo-width: $logo-width * 0.55;
$header-sticky-logo-height: $logo-height * 0.55;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: $spacer * 0.25;
$header-sticky-header-content-margin-bottom: $spacer * 0.25;
$header-sticky-nav-margin-bottom: 0;

//
// Navigation
//

$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $_blue;
$nav-text-transform: lowercase;

// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-large;
$nav-top-level-item-font-weight: $font-weight-normal;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $white;
$nav-top-level-item-hover-background-colour: rgba($black, 0.1);
$nav-top-level-first-item-home-icon: false;
// $nav-top-level-first-item-home-icon-font-size: 20px;
// $nav-top-level-first-item-home-icon-font-size: $font-size-base;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $white;
$nav-submenu-box-shadow: 0 3px 0 $_blue;
$nav-submenu-border-radius: 0;
$nav-submenu-item-padding: 12.5px 20px;
$nav-submenu-item-colour: $brand-primary;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-large;
$nav-submenu-item-font-weight: $font-weight-normal;
$nav-submenu-item-hover-colour: $nav-submenu-item-colour;
$nav-submenu-item-hover-background-colour: rgba($black, 0.075);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour;

// Burger button
$burger-btn-background-colour: $_blue;
$burger-btn-text-colour: $white;
$burger-btn-text-transform: lowercase;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: map-get($breakpoints, sm);

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $_blue;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $white;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: null;
$carousel-contents-max-width: $container-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-background-colour: rgba($white, 0.9);
$carousel-details-box-shadow: 0 3px 0 $_blue;
$carousel-details-max-width: 540px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 1.5;
$carousel-details-margin-y: -2.5vw;
$carousel-details-margin-x: $site-bleed;
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $_blue;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-enabled: true;
$carousel-summary-colour: $body-colour;
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $_blue;
$carousel-read-more-border-colour: $_blue;
$carousel-read-more-colour: $white;
$carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 0px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: none;
$carousel-controls-button-box-shadow: none;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-y: 0px;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.65em;
$carousel-controls-next-icon: "\f054";
$carousel-controls-prev-icon: "\f053";

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: true;
$carousel-buttons-type: dots;

// Carousel buttons dots
$carousel-dots-position-y: bottom; // top, bottom
$carousel-dots-position-x: right; // left, center, right
$carousel-dots-padding: $spacer $spacer * 2;
$carousel-dots-colour: $white;
$carousel-dots-opacity: 0.5;
$carousel-dots-active-colour: $brand-primary;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 50%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
$carousel-scroll-btn-size: $carousel-controls-button-size;
$carousel-scroll-btn-background-colour: $btn-background-colour;
$carousel-scroll-btn-border-radius: $border-radius;
$carousel-scroll-btn-box-shadow: $box-shadow;
$carousel-scroll-btn-icon-colour: $btn-text-colour;
$carousel-scroll-btn-icon-font-size: 1em;
// $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
$carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
$carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel after breakpoint
$carousel-mobile-details-background-colour: rgba($_blue, 0.85);
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: auto;

// Home intro
$home-intro-max-width: 100%;
// $home-intro-background-colour: $grey-lightest;
$home-intro-background-colour: $white;
$home-intro-background-image: null;
$home-intro-background-image-opacity: 1;
$home-intro-box-shadow: $box-shadow;
$home-intro-content-max-width: 1080px;
$home-intro-content-align: center; // left, center or right
$home-intro-padding-y: $spacer * 7.5;
$home-intro-margin-top: $spacer * 2.5;
$home-intro-margin-bottom: $spacer * 1.25;
$home-intro-text-align: center;
$home-intro-text-transform: none;
$home-intro-font-family: $headings-font-family;
$home-intro-font-size: 1.5rem;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: text-contrast($home-intro-background-colour);
$home-intro-border-radius: $border-radius;


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 1.25;
$home-features-cards-entry-animation-prefab: 2;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: $spacer * 2.5;
$impact-stats-margin-bottom: $spacer * 2.5;
$impact-stats-padding-y: $spacer * 7.5;
$impact-stats-background-colour: #FFA06A;
//$impact-stats-background-gradient: $_gradient-red-dark-blue;
// $impact-stats-background-image: "blue-bg.png";
// $impact-stats-background-image-opacity: 1;
// $impact-stats-background-image-size: contain;
// $impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center; // left, center, right
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column; // column, row for the .homeImpactWrapper
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: 3.5em;
$impact-stats-heading-text-align: $impact-stats-text-align;
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 2.5;

// Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour;
$impact-stat-border-width: 0 0 0 0;

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4.5em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: 0;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 2.5;
$home-feeds-padding-bottom: $spacer * 2.5;
$home-feeds-cards-entry-animation-prefab: 0; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h1;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Footer
//

$footer-boxes-count: 4;
$footer-prefab: 1;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 2.5;
$footer-background-colour: $body-colour;
$footer-colour: $white;
$footer-link-colour: rgba($white, 0.55);
$footer-text-align: left;
$footer-font-size: $font-size-base;
$footer-headings-font-size: $font-size-h2;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $brand-primary;
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h1;
$newsletter-heading-text-align: center;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px;
$newsletter-button-background-colour: $_red;
$newsletter-button-border-colour: $_red;
$newsletter-use-placeholders: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 4.5;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;

// Posts
$post-content-max-width: $container-max-width;

// Header text
$header-text-margin-top: $spacer * 2.5;
$header-text-margin-bottom: $spacer * 1.25;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left; // left, center or right
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;

// Sidebar
$sidebar-enabled: true;
$sidebar-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 2.5;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 6;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-large;
$associated-item-summary-enabled: true;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-cards-per-row-desktop: 4;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: false;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: false;
$listed-appeal-totaliser-enabled: false;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align;

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $brand-primary;
$blockquote-background-gradient: $_gradient-red-dark-blue;
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 2.5;
$blockquote-padding: $spacer * 1.5;
$blockquote-text-align: center;
$blockquote-font-size: 1.5em;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $white;
$blockquote-box-shadow: none;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: false;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: false;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: true;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $_red;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $grey-lightest;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: 0;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $body-colour;
$quick-giving-donation-amount-border-width: 3px;
$quick-giving-donation-amount-border-colour: $body-colour;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $_red;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 2.5;
$home-quick-giving-background-colour: $grey-lightest;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 3px;
$home-quick-giving-donation-amount-border-colour: $body-colour;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $_red;
$home-quick-giving-donate-btn-background-colour: $_red;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: $_red;
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $body-colour;
$home-quick-giving-heading-font-size: $font-size-h1;

// Totalisers
$totaliser-enabled: true !default;
$totaliser-layout: horizontal !default; // vertical or horizontal
$totaliser-width: 60px !default;
$totaliser-panel-background-colour: transparent !default;

// Comments
$comment-add-image-enabled: true !default;
$comment-add-video-enabled: true !default;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour !default;
$blog-details-padding: $context-container-padding !default;
$blog-details-border-radius: $context-container-border-radius !default;
$blog-details-profile-pic-enabled: $profile-pics-enabled !default;

// FAQs
$faq-content-max-width: $post-content-max-width !default;
$faq-item-header-background-colour: $context-container-background-colour !default;
$faq-item-header-padding: $context-container-padding !default;
$faq-item-header-border-radius: $context-container-border-radius !default;
$faq-item-header-font-size: $font-size-h4 !default;
$faq-item-btn-background-colour: $btn-background-colour !default;
$faq-item-btn-chevron-colour: $btn-text-colour !default;
$faq-item-hover-btn-background-colour: button-hover-state(
  $faq-item-btn-background-colour
) !default;
$faq-item-hover-btn-chevron-colour: $white;

// Shop
$shop-enabled: true !default; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

$basket-link-only-on-shop-pages: false !default; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only

// Departments list
$department-list-width: $sidebar-adjacent-width !default;
$department-list-background-colour: $context-container-background-colour !default;
$department-list-padding: $context-container-padding !default;
$department-list-border-radius: $context-container-border-radius !default;

// Product-price
$product-price-colour: $brand-secondary !default;
$product-price-font-size: $font-size-h4 !default;

// Product post
$product-post-content-max-width: $post-content-max-width !default;

// Cookie consent
$cookie-consent-position: top;
$cookie-consent-background-colour: $grey-dark;
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: $font-family-base;
$cookie-consent-btn-background-colour: $btn-background-colour;
$cookie-consent-btn-text-colour: text-contrast($cookie-consent-btn-background-colour);

$subsite-enabled: true;
$subsite-logo: "logo-subsite.png";
$subsite-logo-width: $logo-width*1.5;